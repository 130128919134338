<template>
  <a-modal
    class="file-dialog--pdf"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    title="合同预览"
    width="1000px"
    :okButtonProps="{
      props: {loading}
    }"
  >
    <div class="content__pdf">
      <iframe id="PDF" :src="filePath" width="100%" height="800px"></iframe>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'FileDialog',
  props: {
    callback: Function
  },
  data () {
    return {
      visible: false,
      filePath: '',
      loading: false
    }
  },
  methods: {
    $init (path) {
      this.filePath =decodeURIComponent(path)
      this.visible = true
    },
    handleOk () {
      this.$emit('handleOk')
      this.loading = true
      if (this.callback) {
        this.callback()
          .then(() => {
            this.handleCancel()
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.handleCancel()
        this.loading = false
      }
    },
    handleCancel () {
      this.visible = false
      this.filePath = null
    }
  }
}
</script>
